import React from 'react'
import Head from 'next/head'

interface ISEOProps {
  title?: string
  keywords?: string
  description?: string
  canonical?: string
  ogImage?: string
  ogImageAlt?: string
  ogImageWidth?: string
  ogImageHeight?: string
  noIndex?: boolean
}

const SEO: React.FC<ISEOProps> = ({
  title = 'Instagram Summit 2022 | ManyChat | Home',
  keywords = 'Instagram, Instagram Summit, Marketing, Event, ManyChat, Automation',
  description = 'ManyChat’s Instagram Summit, a social media marketing conference, features top influencer experts revealing to Instagram strategies create content and convert likes and comments into sales.',
  canonical,
  ogImage = `https://${process.env.NEXT_PUBLIC_DOMAIN}/og.png`,
  ogImageAlt = 'Instagram Summit 2022',
  ogImageWidth = '1200',
  ogImageHeight = '630',
  noIndex = false,
}) => (
  <Head>
    <meta httpEquiv="Content-type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" />
    <meta name="Keywords" content={keywords} />
    <title>{title}</title>
    <meta name="description" content={description} />
    {canonical ? <link rel="canonical" href={canonical} /> : ''}
    <script type="application/ld+json">
      {`{
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Instagram Summit",
          "url": "https://${process.env.NEXT_PUBLIC_DOMAIN}",
          "logo": "https://${process.env.NEXT_PUBLIC_DOMAIN}/logo.svg"
        }`}
    </script>
    <meta property="og:title" content={title} />
    <meta property="og:locale" content="en_US" />
    <meta
      property="og:url"
      content={canonical ? canonical : `https://${process.env.NEXT_PUBLIC_DOMAIN}`}
    />
    <meta property="og:type" content="website" />
    <meta property="og:description" content={description} />
    <meta property="og:site_name" content={`https://${process.env.NEXT_PUBLIC_DOMAIN}`} />
    <meta property="og:image" content={ogImage} />
    <meta property="og:image:alt" content={ogImageAlt || 'IGSummit Cover Image'} />
    <meta property="og:image:width" content={ogImageWidth || '1200'} />
    <meta property="og:image:height" content={ogImageHeight || '630'} />
    <meta property="fb:app_id" content="532160876956612" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    <meta name="twitter:image" content={ogImage} />
    {noIndex ? <meta name="robots" content={'noindex, nofollow'} /> : ''}
    <link rel="image_src" href={ogImage} />
    <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
    <link rel="alternative icon" type="image/png" href="/favicon.png" />
  </Head>
)

export default SEO
