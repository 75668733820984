import image1 from 'public/features/image-1.png'
import image2 from 'public/features/image-2.png'
import image3 from 'public/features/image-3.png'
import image4 from 'public/features/image-4.png'

const features = [
  {
    head: 'Outsmart the algorithm, grow your followers & reach more prospects',
    text: (
      <p className="text-s">
        Instagram’s top experts spill the secrets on how to master the platform’s features -
        including Reels and Stories - to craft content that outperforms your competitors’ and
        attracts more adoring fans. 👀
      </p>
    ),
    image: image1,
  },
  {
    head: 'Convert low-ROI posts into attention-grabbing sales machines',
    text: (
      <p className="text-s">
        Get the proven shortcuts from bonafide professionals who have grown an irresistible brand on
        Instagram, including foolproof methods to squeeze thousands of dollars in revenue from the
        platform.
      </p>
    ),
    image: image2,
  },
  {
    head: 'Unlock the power of Instagram Automation',
    text: (
      <p className="text-s">
        Discover easy ways to leverage Instagram-approved chatbots to automate your DM conversations
        while crushing sales goals, and delivering five-star customer satisfaction. 🙌
      </p>
    ),
    image: image3,
  },
  {
    head: 'Make valuable connections in a global community',
    text: (
      <p className="text-s">
        With 8,000+ attendees spanning 170 countries, you can swap stories, tips, and hacks with
        industry trendsetters. Plus, flaunt your “desk dance” at our virtual VIP after party! 🥳
      </p>
    ),
    image: image4,
  },
]

export default features
