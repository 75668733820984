import Speaker, { ISpeaker } from 'components/Speaker'
import { Button } from '@martech/ig-summit-ui'

export interface ISpeakers {
  speakers: ISpeaker[]
}

const Speakers: React.FC<ISpeakers> = ({ speakers }) => (
  <section className="m_t-xl m_b-xxl">
    <h2 className="text-center m_y-xl">Speakers</h2>
    <div className={`scroll-x p-m_b-scrollbar`}>
      <div className="line flex">
        {speakers.map((speaker) => (
          <Speaker speaker={speaker} key={speaker.slug} className="shrink_0" />
        ))}
      </div>
    </div>
    <div className="m_t-l t-flex jcc m_x-xs">
      <Button href="/speakers">More Speakers</Button>
    </div>
  </section>
)

export default Speakers
