import { FC, ReactNode } from 'react'
import styles from './Features.module.css'
import Image, { StaticImageData } from 'next/image'

export interface IFeature {
  count: number
  head: string
  text: ReactNode
  image: StaticImageData
  odd: boolean
}

const Feature: FC<IFeature> = ({ count, head, text, image, odd }) => {
  return (
    <div className="t-grid gap t-grid_2 aic">
      <div className={`${odd ? '' : 'last'} flex jcc`}>
        <div className={styles.image}>
          <Image src={image} alt={`${head} image`} />
        </div>
      </div>
      <div className={`${odd ? '' : 'first'} ${styles.right}`}>
        <h2>{count}</h2>
        <h4>{head}</h4>
        {text}
      </div>
    </div>
  )
}

export default Feature
