import { ITrack } from 'components/Track'

const tracks: ITrack[] = [
  {
    name: (
      <>
        Instagram
        <br />
        Growth
      </>
    ),
    description: (
      <p>
        Unlock the little-known tricks to&nbsp;optimize your bio, get crystal-clear on&nbsp;your
        brand voice and content, and craft scroll-stopping captions to&nbsp;amplify engagement and
        grow your followers faster than you could ever imagine!
      </p>
    ),
  },
  {
    name: 'Agencies',
    description: (
      <p>
        Steal the repeatable systems from top marketing masterminds whose hyper-accelerated growth
        tactics have changed the game. (Plus, you can implement them as&nbsp;soon as&nbsp;the
        session ends.)
      </p>
    ),
  },
  {
    name: (
      <>
        Content
        <br />
        Creators
      </>
    ),
    description: (
      <p>
        The platform&rsquo;s #1 creators and social commerce heavyweights unpack their favorite ways
        to grow an&nbsp;ultra-successful and profitable brand on&nbsp;Instagram without being
        at&nbsp;the mercy of&nbsp;the algorithm.
      </p>
    ),
  },
  {
    name: (
      <>
        Automation
        <br />
        Workshops
      </>
    ),
    description: (
      <>
        <p>
          Go&nbsp;in-depth into the Instagram DM&nbsp;Automation product features and gain rare
          insights to create captivating campaigns to&nbsp;smash those important sales targets.
        </p>
        <p>
          Automation Workshops are available for Power Pass attendees only. Want in? Email{' '}
          <a href="mailto:events@manychat.com">events@manychat.com</a> so&nbsp;we&nbsp;can upgrade
          you to&nbsp;a&nbsp;Power Pass so&nbsp;you have priority access to&nbsp;ALL Automation
          Workshops. 🙌
        </p>
      </>
    ),
  },
]

export default tracks
