import type { AppProps } from 'next/app'
import '@martech/ig-summit-ui/src/styles/main.css'
import { CookieBumper, TypoHero, Button } from '@martech/ig-summit-ui'
import { useBackground } from '@martech/ig-summit-ui/utils'
import { MouseEvent, useEffect, useState } from 'react'
import Navigation from 'components/Navigation'
import { useRouter } from 'next/router'
import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/dist/ScrollToPlugin'
import UkraineBanner from 'components/Ukraine'
import Header from 'components/Header'

gsap.registerPlugin(ScrollToPlugin)

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  useBackground(router.pathname)
  const [navIsOpen, setNavIsOpen] = useState(false)

  const toggleNavOpen = () => setNavIsOpen((prevState) => !prevState)

  const navClose = () => setNavIsOpen(false)

  const handleNavClick = (name: string, e: MouseEvent<HTMLAnchorElement>) => {
    setNavIsOpen(false)
    if (router.route !== '/') return
    e.preventDefault()
    window.history.pushState({}, '', e.currentTarget.href)
    gsap.to(window, {
      duration: 0.7,
      ease: 'circ.inOut',
      scrollTo: { y: `#${name}` },
    })
  }

  return (
    <>
      <CookieBumper />
      {!router.route.includes('on-demand') && <UkraineBanner />}

      {router.route === '/' && (
        <>
          <div className="set-color" data-back="#ffdb28" data-front="#020202">
            <TypoHero />
          </div>
          <hr className="m_x-xs" />
        </>
      )}
      {!router.route.includes('on-demand') && (
        <Navigation
          onClick={handleNavClick}
          isOpen={navIsOpen}
          toggleOpen={toggleNavOpen}
          close={navClose}
        />
      )}
      {router.route.includes('on-demand') && <Header />}

      <Component {...pageProps} />

      <>
        <hr className="m_x-xs" />
        <div className="set-color" data-back="#ffdb28" data-front="#020202">
          {!router.route.includes('on-demand') && (
            <>
              <TypoHero />
              <hr className="m_x-xs" />
              <div className="m_t-s mb_xxl text-m-bolder text-center">
                Grab a seat for two days of workshops
                <br />
                and presentations 👇
              </div>
              <div className="m_t-m m_b-l m_x-xs t-flex jcc">
                <Button href="https://events.hubilo.com/igsummit-2022" target="_blank">
                  Go to Summit
                </Button>
              </div>
            </>
          )}

          <div className="jcc flex text-action">
            <div className="p_y-xs p_x-xxs">©2022, ManyChat, Inc.</div>
            <a href="https://manychat.com/privacy.html" target="_blank" className="p_y-xs p_x-xxs">
              Privacy Policy
            </a>
            <a href="https://manychat.com/legal/tos" target="_blank" className="p_y-xs p_x-xxs">
              Terms of Service
            </a>
          </div>
        </div>
      </>
    </>
  )
}

export default MyApp
