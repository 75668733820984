import { useEffect } from 'react'

export const useBackground = (pathname: string) => {
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          document.documentElement.style.setProperty(
            '--back',
            (entry.target as HTMLDivElement).dataset.back ||
              document.documentElement.style.getPropertyValue('--back'),
          )
          document.documentElement.style.setProperty(
            '--front',
            (entry.target as HTMLDivElement).dataset.front ||
              document.documentElement.style.getPropertyValue('--front'),
          )
        }
      })
    }, options)

    const sections = document.querySelectorAll('.set-color')
    sections.forEach((section) => {
      observer.observe(section)
    })

    return () => {
      observer.disconnect()
    }
  }, [pathname])
}
