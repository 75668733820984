import { getCookie, setCookie } from '../../../utils'
import { useEffect, useState } from 'react'
import Button from '../Button'
import styles from './CookieBumper.module.css'

const COOKIE_NAME = 'mchtca'

const CookieBumper = () => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (!getCookie(COOKIE_NAME)) {
      setTimeout(() => {
        setIsVisible(true)
      }, 1000)
    }
  }, [])

  const handleClick = (e: MouseEvent) => {
    e.preventDefault()
    setIsVisible(false)
    setCookie(COOKIE_NAME, '!', 99999)
  }

  return (
    <div className={`${styles.container} ${isVisible ? styles['is-visible'] : ''}`}>
      <hr className="m_x-xs" />
      <div className={`m_x-xs grid gap_xs jcc aic ${styles.inner}`}>
        <p className="text-xs">
          We use cookies and you consent to our collection of them for this website.
        </p>
        <div className={`grid t-gap_xs jcc aic ${styles.links}`}>
          <Button size="s" tag="div" className={styles.button} onClick={handleClick} sign="👌">
            Ok
          </Button>
          <div>
            <a href="https://manychat.com/legal/privacy" target="_blank" className="link text-xs">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieBumper
