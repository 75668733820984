import { FC, ReactNode } from 'react'
import styles from './Track.module.css'

export interface ITrack {
  name: ReactNode
  description: ReactNode
}

const Track: FC<ITrack> = ({ name, description }) => {
  return (
    <div className={`p_x-xs ${styles.track}`}>
      <h4 className={`${styles.name} flex aic jcc text-center`}>{name}</h4>
      <article className="text-s">{description}</article>
    </div>
  )
}

export default Track
