import { FC, useEffect, useRef, useState } from 'react'
import styles from './WistiaVideo.module.css'
import { getCookie } from '../../../utils'
import Preloader from '../Preloader'
import Image, { StaticImageData } from 'next/image'
import bg from './video-background.png'

type Video = Record<string, any>

export interface IWistiaVideoProps {
  previewImage: StaticImageData
  videoId: string
  className?: string
}

const WistiaVideo: FC<IWistiaVideoProps> = ({ previewImage, videoId, className }) => {
  const [isPlay, setIsPlay] = useState(false)
  const videoRef = useRef<Video | null>(null)
  const wistiaRef = useRef<HTMLDivElement>(null)

  const createScript = (src: string, scripts: NodeListOf<HTMLScriptElement>, body: HTMLElement) => {
    let embedScript = false
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].src === src) {
        embedScript = true
        break
      }
    }
    if (!embedScript) {
      const script = document.createElement('script')
      script.src = src
      script.async
      body.append(script)
    }
  }

  const email = useRef<null | string>(null)
  const handlePlay = () => {
    if (!isPlay) setIsPlay(true)
  }

  useEffect(() => {
    if (isPlay) {
      const body = document.body
      const scripts = document.querySelectorAll('script')
      createScript('https://fast.wistia.com/assets/external/E-v1.js', scripts, body)
      createScript(`https://fast.wistia.com/embed/medias/${videoId}.jsonp`, scripts, body)
      email.current = `${getCookie('mchtClientId')}-wistia@manychat.com`
      window._wq = window._wq || []
      if (typeof window._wq?.push === 'function') {
        window._wq.push(
          {
            id: videoId,
            onHasData: (video: Video) => {
              if (email.current) video.email(email.current)
            },
          },
          {
            id: videoId,
            onReady: (video: Video) => {
              videoRef.current = video
              if (!email.current) {
                video.bind('conversion', () => {
                  setTimeout(() => {
                    video.play()
                  }, 0)
                })
              }
              video.play()
            },
          },
        )
      }
    }
  }, [isPlay])

  return (
    <div className={styles.wrapper}>
      <div className={styles['video-background']}>
        <Image src={bg} alt="Branded video background" fill />
      </div>
      <div className={`${styles['video-container']} ${className ? className : ''}`} ref={wistiaRef}>
        {isPlay ? (
          <div className={styles['wistia-video']}>
            <Preloader />
            <div
              className={`wistia_embed wistia_async_${videoId} videoFoam=true autoPlay=true playerColor=1c1c28 settingsColntrols=false wmode=transparent`}
              style={{ height: '100%', position: 'relative', width: '100%' }}
            />
          </div>
        ) : (
          <div className={`play ${styles.thumbnail}`} onClick={handlePlay}>
            <Image src={previewImage} alt="Video Preview Image" />
          </div>
        )}
      </div>
    </div>
  )
}

export default WistiaVideo
