import Track, { ITrack } from 'components/Track'
import styles from './Tracks.module.css'
import { Button } from '@martech/ig-summit-ui'

export interface ITracks {
  tracks: ITrack[]
}

const Tracks: React.FC<ITracks> = ({ tracks }) => (
  <section className={`m_t-xl relative`}>
    <h2 className="p_x-xs m_y-xl text-center">The tracks</h2>
    <div className="scroll-x">
      <div className="line flex shrink_0">
        {tracks.map((track, i) => (
          <Track {...track} key={i} />
        ))}
      </div>
    </div>
    <div className={`p_x-xs t-flex jcc ${styles.button}`}>
      <Button href="https://events.hubilo.com/igsummit-2022" target="_blank">
        Go to Summit
      </Button>
    </div>
  </section>
)

export default Tracks
