import Image, { StaticImageData } from 'next/image'
import { FC } from 'react'
import Link from 'next/link'
import styles from './Speaker.module.css'

export interface ISpeaker {
  photo: StaticImageData
  photoPng: StaticImageData
  firstName: string
  lastName: string
  role: string
  company?: string
  slug: string
  track?: string
  instagram?: string
  bio?: string
}

interface IProps {
  speaker: ISpeaker
  isActive?: boolean
  className?: string
}

const Speaker: FC<IProps> = ({
  speaker: { photo, firstName, lastName, role, slug },
  isActive,
  className = '',
}) => {
  return (
    <div className={`text-black ${className}`}>
      <Link
        href={`/speakers/${slug}`}
        className={`block m_x-xs bg-white ${isActive ? styles.active : ''} ${styles.card}`}
      >
        <div className={styles.image}>
          <Image src={photo} placeholder="blur" alt={`${firstName} ${lastName} photo`} />
        </div>
        <div className={styles.text}>
          <h3 className={`text-m-accident m_b-xs`}>
            {firstName} <br />
            {lastName}
          </h3>
          <p className={`text-xs text-center`}>{role}</p>
        </div>
      </Link>
    </div>
  )
}

export default Speaker
