import { IBenefit } from 'components/Benefits'
import icon1 from 'public/benefits/icon-1.png'
import icon2 from 'public/benefits/icon-2.png'
import icon3 from 'public/benefits/icon-3.png'

const benefits: IBenefit[] = [
  { image: icon1, title: '25k+', subline: 'attendees from more than 170 countries' },
  { image: icon2, title: '27', subline: 'internationally renowned experts' },
  { image: icon3, title: '29', subline: 'unmissable sessions over 2 days' },
]

export default benefits
