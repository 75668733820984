import styles from './Ticket.module.css'
import { FC, ReactNode } from 'react'
import { Button } from '@martech/ig-summit-ui'

export interface ITicket {
  name: string
  params: number
  price: ReactNode
  priceSubline?: string
  disclaimer?: string
  paramsList: string[]
  startDate?: string
  endDate?: string
}

const currentDate = new Date()

const Ticket: FC<ITicket> = ({
  name,
  params,
  price,
  priceSubline = '',
  disclaimer = '',
  paramsList,
}) => {
  return (
    <div className={`text-black ${styles.noactive}`}>
      <div className="bg-white m_x-xs p_x-xs p_t-s flex fdc jcsb height overflow">
        <h4 className={`m_b-xs ${styles.name}`}>{name}</h4>
        <ul className={styles.ul}>
          {paramsList.map((param, i) => (
            <li
              className={`text-xs ${styles.li} ${(params >> i) & 1 ? styles.yes : styles.no}`}
              key={param}
            >
              {param}
            </li>
          ))}
        </ul>
        <div className="m_t-l">
          <div className={`${styles.price} m_b-xs`}>
            <h4>{price}</h4>
            {priceSubline && <p className={`text-xs ${styles['bottom-text']}`}>{priceSubline}</p>}
          </div>
          <Button href="https://events.hubilo.com/igsummit-2022/register" size="s" target="_blank">
            Get Ticket
          </Button>
          <div className={styles.bottom}>
            <p className="p_y-xs text-xs text-center flex jcc aic">{disclaimer}</p>
            <img className={styles.barcode} src="barcode.svg" alt="barcode" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Ticket
