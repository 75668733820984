import Image, { StaticImageData } from 'next/image'
import styles from './Benefits.module.css'

export interface IBenefit {
  image: StaticImageData
  title?: string
  subline?: string
}

export interface IBenefits {
  benefits: IBenefit[]
  className?: string
}

const Benefit: React.FC<IBenefit> = ({ image, title, subline }) => (
  <div className={`p_x-xs text-center ${styles.benefit}`}>
    <div className={styles.image}>
      <Image src={image} alt="" />
    </div>
    {title && <h4 className="text-xl m_b-xxxs">{title}</h4>}
    {subline && <p className="text-s m_b-xxxs">{subline}</p>}
  </div>
)

const Benefits: React.FC<IBenefits> = ({ benefits, className = 'm_y-s' }) => (
  <div className={`${styles.benefits} ${className}`}>
    {benefits.map((benefit, i) => (
      <Benefit {...benefit} key={i} />
    ))}
  </div>
)

export default Benefits
