import styles from './Promo.module.css'
import Image, { StaticImageData } from 'next/image'
import { FC, ReactNode } from 'react'

interface IPromo {
  image: StaticImageData
  children: ReactNode
}

const Promo: FC<IPromo> = ({ image, children }) => (
  <section className="grid t-grid_2">
    <div className={`p_a-xs ${styles.image}`}>
      <Image src={image} alt="" />
    </div>
    <article className={`text-m p_x-xs p_y-s`}>{children}</article>
  </section>
)

export default Promo
