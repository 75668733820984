import { FC } from 'react'
import styles from './Preloader.module.css'

const Preloader: FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.dot} />
    <div className={styles.dot} />
    <div className={styles.dot} />
  </div>
)

export default Preloader
