import { ITicket } from 'components/Ticket'

const paramsList = [
  'Access to keynotes and breakouts',
  'Networking',
  'Access to Automation Workshops',
  'Tutorials and guides',
  'Access to networking and parties',
  '6 months access to on-demand replays',
  'Birds of a Feather interactive sessions access',
  '30 days FREE ManyChat PRO Account (for NEW accounts only)',
  'All digital products valued at $499',
]

const tickets: ITicket[] = [
  {
    name: 'Starter Pass',
    params: 0b000000011,
    price: 'Free',
    paramsList,
  },
  {
    name: 'Early Bird Power Pass 💪',
    params: 0b111111111,
    price: (
      <>
        Just <span>49</span>
      </>
    ),
    priceSubline: 'Save $50 on Regular VIP pricing! ',
    disclaimer: 'Hurry! Early Bird pricing ends March\u00A04th,\u00A02022',
    paramsList,
    endDate: 'Mar 04 2022 23:59:59 UTC-0800',
  },
  {
    name: 'Last Chance Power Pass',
    params: 0b111111111,
    price: (
      <>
        <span>69</span>
      </>
    ),
    disclaimer: 'Pricing opens March\u00A05th,\u00A02022',
    paramsList,
    startDate: 'Mar 05 2022 00:00:00 UTC-0800',
    endDate: 'Mar 25 2022 00:00:00 UTC-0700',
  },
  {
    name: 'Regular Power Pass',
    params: 0b111111111,
    price: (
      <>
        <span>99</span>
      </>
    ),
    disclaimer: 'Pricing opens March\u00A025th,\u00A02022',
    paramsList,
    startDate: 'Mar 25 2022 00:00:00 UTC-0700',
  },
]

export default tickets
