import { IAgendaDay as IMainPageAgendaDay } from 'components/Agenda'
import networking from 'public/agenda/networking.png'
import mikeYan from 'public/agenda/mike-yan.png'
import wonji from 'public/agenda/wonji.png'
import jenn from 'public/agenda/jenn.png'
import monique from 'public/agenda/monique.png'
import lucas from 'public/agenda/lucas.png'
import angela from 'public/agenda/angela.png'
import trilce from 'public/agenda/trilce.png'
import christos from 'public/agenda/christos.png'
import konstantinos from 'public/agenda/konstantinos.png'
import mikeNelson from 'public/agenda/mike-nelson.png'
import kellyNoble from 'public/agenda/kelly-noble.png'
import charlene from 'public/agenda/charlene.png'
import candace from 'public/agenda/candace.png'
import lunch from 'public/agenda/lunch.png'
import brock from 'public/agenda/brock.png'
import seth from 'public/agenda/seth.png'
import jaclyn from 'public/agenda/jaclyn.png'
import brian from 'public/agenda/brian.png'
import dot from 'public/agenda/dot.png'
import mario from 'public/agenda/mario.png'
import ashley from 'public/agenda/ashley.png'
import lattice from 'public/agenda/lattice.png'
import lauren from 'public/agenda/lauren.png'
import mackensie from 'public/agenda/mackensie.png'
import molly from 'public/agenda/molly.png'
import natalie from 'public/agenda/natalie-sueb.png'
import vip from 'public/agenda/vip-party.png'
import { ReactNode } from 'react'
import { StaticImageData } from 'next/image'

export interface IAgenadaItem {
  time: string
  title: ReactNode
  image: StaticImageData
  description?: ReactNode
}

export interface IAgendaStage {
  title?: 'Content Creators' | 'Agencies' | 'Instagram Growth' | 'Automation Workshops*'
  note?: string
  items: IAgenadaItem[]
}

export interface IAgendaGroup {
  breakouts: IAgendaStage[]
  title: string
}

export interface IAgendaDay {
  title: string
  date: string
  timelineGroups: IAgendaGroup[]
}

export const day1: IAgendaDay = {
  title: 'Day 1',
  date: 'April 5th (PDT)',
  timelineGroups: [
    {
      title: 'Mainstage',
      breakouts: [
        {
          items: [
            {
              time: '8:00–9:00 AM',
              title: 'Networking',
              image: networking,
            },
            {
              time: '9:00–9:50 AM',
              title: 'Mike Yan, Co-Founder & CEO of ManyChat',
              image: mikeYan,
              description: 'Hypergrowth with Instagram DM Automation',
            },
            {
              time: '9:50–10:00 AM',
              title: (
                <>
                  <p>Wonji Choi, Product Marketing Manager, Business Messaging at Meta</p>
                  <p>Moderator: Anna Tutckaia, Head of Marketing at ManyChat</p>
                </>
              ),
              image: wonji,
              description: 'Fireside Chat with Meta on the Power of Instagram Commerce',
            },
          ],
        },
      ],
    },
    {
      title: 'Breakouts',
      breakouts: [
        {
          title: 'Content Creators',
          items: [
            {
              time: '10:05 AM',
              title: "Jenn Herman, Founder of Jenn's Trends",
              image: jenn,
              description: 'The Next Big Instagram Trends You Need to Know Now',
            },
            {
              time: '10:40 AM',
              title:
                'Monique Lombardo, Founder of The Socialista and Co-Creator of Rise With Reels',
              image: monique,
              description:
                'Followers to Fans: How to Use Instagram Reels to Grow Your Brand (& Your Revenue)',
            },
            {
              time: '11:20 AM',
              title: "Lucas O'Keefe, Content Creator and Social Media Strategist",
              image: lucas,
              description:
                'The 7-Day Action Plan for Explosive Instagram Growth in 2022 and Beyond',
            },
          ],
        },
        {
          title: 'Agencies',
          items: [
            {
              time: '10:05 AM',
              title: 'Angela Allan, Founder of Mads Collective Group',
              image: angela,
              description:
                'The $1.50 A Day Instagram Content Strategy to Gain 30+ Loyal Followers in One Week',
            },
            {
              time: '10:40 AM',
              title: 'Trilce Jirón Garro, CEO of TBS Marketing',
              image: trilce,
              description:
                'From DMs to Dollars: Triple Your Sales Using Instagram DM Automation & a CRM System',
            },
          ],
        },
        {
          title: 'Instagram Growth',
          items: [
            {
              time: '10:05 AM',
              title: 'Christos Nikas, Content Strategist',
              image: christos,
              description: 'Content Domination to Grow Your Instagram (& Your Bank Account)',
            },
            {
              time: '10:40 AM',
              title: 'Konstantinos Synodinos, CEO of Simply Digital',
              image: konstantinos,
              description:
                'The Ultimate Client Pick-Up Kit: Stop Chasing Prospects & Build a Community Instead',
            },
            {
              time: '11:20 AM',
              title: 'Mike Nelson, Founder & CEO of The Passion To Profit Coaching',
              image: mikeNelson,
              description: 'DMs to Deals: How to Land More High-Ticket Clients Using Instagram DMs',
            },
          ],
        },
        {
          title: 'Automation Workshops*',
          note: '*For Power Pass Holders Only',
          items: [
            {
              time: 'On-demand',
              title: 'Kelly Noble Mirabella, Founder of Stellar Media Marketing & Baby Got Bot',
              image: kellyNoble,
              description:
                'How to Leverage Instagram DM Automation to Pinpoint, Prime & Persuade More Ideal Clients... On Autopilot',
            },
            {
              time: 'On-demand',
              title: 'Charlene Ormo, Co-Founder & CEO of After Click Media',
              image: charlene,
              description: 'Flood Your Pipeline With Leads & Sales Using Instagram DM Automation',
            },
            {
              time: 'On-demand',
              title: 'Candace Junée, Business Coach & Digital Marketing Strategist',
              image: candace,
              description:
                "The Ultimate Instagram Passive Income Funnel (That Your Competition Doesn't Know About)",
            },
          ],
        },
      ],
    },
    {
      title: 'Mainstage',
      breakouts: [
        {
          items: [
            {
              time: '12:00–1:00 PM',
              title: 'Birds of a Feather & Lunch Break',
              image: lunch,
              description: (
                <>
                  <p>Click on the Rooms tab, to join the BoF session on: Latinos*</p>
                  <p> *For Power Pass Holders Only</p>
                </>
              ),
            },
            {
              time: '1:00–1:30 PM',
              title: 'Brock Johnson, CEO of InstaClubHub',
              image: brock,
              description: 'How to Use Instagram Reels to Skyrocket Your Growth',
            },
            {
              time: '1:35–2:05 PM',
              title: 'Seth Godin, Bestselling Author, Teacher, & Entrepreneur',
              image: seth,
              description: 'The Future of Marketing & Automation with Seth Godin',
            },
          ],
        },
      ],
    },
  ],
}

export const day2: IAgendaDay = {
  title: 'Day 2',
  date: 'April 6th (PDT)',
  timelineGroups: [
    {
      title: 'Mainstage',
      breakouts: [
        {
          items: [
            { time: '8:00–9:00 AM', title: 'Networking', image: networking },
            {
              time: '9:00–9:45 AM',
              title: (
                <>
                  <p>Jaclyn Johnson, Founder & CEO of Create & Cultivate</p>
                  <p>Moderator: Angela Allan, Founder of Mads Collective Group</p>
                </>
              ),
              image: jaclyn,
              description: 'In Conversation with Jaclyn Johnson',
            },
            {
              time: '9:45–10:15 AM',
              title: 'Brian Donnelly, Product Marketing Lead, Business Messaging at Meta',
              image: brian,
              description: 'An Introduction to CTX with Meta Inc',
            },
          ],
        },
      ],
    },
    {
      title: 'Breakouts',
      breakouts: [
        {
          title: 'Content Creators',
          items: [
            {
              time: '10:15 AM',
              title: 'Dot Lung, Mother of Social Dragons',
              image: dot,
              description: 'The Secrets of Influence: Personal Branding for Business Growth',
            },
            {
              time: '11:00 AM',
              title: 'Mario Moreno, Founder & CEO of Moonrise Social Club',
              image: mario,
              description: 'The Golden Rules for Finding Your Ideal Brand Partnership',
            },
          ],
        },
        {
          title: 'Agencies',
          items: [
            {
              time: '10:15 AM',
              title: 'Ashley Ann, Biz Builder, TEDx Speaker, & Social Media Strategist',
              image: ashley,
              description: 'How to Make Thousands on Instagram With Fewer Than 1,000 Followers',
            },
          ],
        },
        {
          title: 'Instagram Growth',
          items: [
            {
              time: '10:15 AM',
              title: 'Lattice Hudson, Founder & CEO of Lattice & Co.',
              image: lattice,
              description:
                'The Automated Social Selling Blueprint to Fill Up Your High Ticket Coaching Program... Fast!',
            },
            {
              time: '11:00 AM',
              title:
                'Lauren Petrullo, Founder & CEO of Award Winning Marketing Agency, Mongoose Media',
              image: lauren,
              description:
                'Social Commerce 101: How to Set Up Your Instagram Shop in 4 Hours to Generate $4K+ Per Month',
            },
          ],
        },
        {
          title: 'Automation Workshops*',
          note: '*For Power Pass Holders Only',
          items: [
            {
              time: 'On-demand',
              title: 'Brock Johnson, CEO of InstaClubHub',
              image: brock,
              description: 'Using ManyChat for Boosting Engagement and Increasing Sales',
            },
            {
              time: 'On-demand',
              title: 'Mackensie Liberman, CEO of Orca Marketology',
              image: mackensie,
              description:
                'Become an Instagram Quiz Master: How to Build Quizzes and Games to Increase Brand Awareness & Drive Sales',
            },
            {
              time: 'On-demand',
              title: 'Molly Mahoney, Founder & CEO of The Prepared Performer',
              image: molly,
              description:
                '5 Quick Instagram DM Automation Strategies to Increase Engagement & Sales',
            },
          ],
        },
      ],
    },
    {
      title: 'Mainstage',
      breakouts: [
        {
          items: [
            {
              time: '12:00–1:00 PM',
              title: 'Birds of a Feather & Lunch Break',
              image: lunch,
              description: (
                <>
                  <p>Click on the Rooms tab, to join the BoF session on: Latinos*</p>
                  <p> *For Power Pass Holders Only</p>
                </>
              ),
            },
            {
              time: '1:00–1:55 PM',
              title: (
                <>
                  <p>
                    Natalie Ellis, Co-Founder and CEO of BossBabe, & Sue B. Zimmerman, Founder
                    of Sue B. Zimmerman Enterprise
                  </p>
                  <p>Moderator: Anna Tutckaia, Head of Marketing at ManyChat</p>
                </>
              ),
              image: natalie,
              description: 'Panel: Women in Instagram Business',
            },
            {
              time: '2:00–2:45 PM',
              title: 'Power Pass VIP Party*',
              image: vip,
              description: (
                <>
                  <p>
                    Join us at the VIP Party with DJ Graffiti. Flaunt your best “desk dance” with
                    your fellow attendees! 🥳 Don’t forget: DJ Graffiti will take requests, so come
                    prepared with a few favorites you want to hear!
                  </p>
                  <p>*For Power Pass Holders Only</p>
                </>
              ),
            },
          ],
        },
      ],
    },
  ],
}

export const mainPageAgenda: IMainPageAgendaDay[] = [
  {
    title: 'Day 1',
    date: 'April 5th (PDT)',
    items: [
      {
        time: '8:00 AM - 9:00 AM',
        title: 'Networking',
      },
      {
        time: '9:00 AM - 10:00 AM',
        title: 'Keynotes',
      },
      {
        time: '10:05 AM - 12:00 PM',
        title: 'Breakouts',
      },
      {
        time: '12:00 PM - 1:00 PM',
        title: 'Birds of a Feather & Lunch Break',
      },
      {
        time: '1:00 PM - 2:05 PM',
        title: 'Keynotes',
      },
    ],
  },
  {
    title: 'Day 2',
    date: 'April 6th (PDT)',
    items: [
      {
        time: '8:00 AM - 9:00 AM',
        title: 'Networking',
      },
      {
        time: '9:00 AM - 10:15 AM',
        title: 'Keynotes',
      },
      {
        time: '10:15 AM - 12:00 PM',
        title: 'Breakouts',
      },
      {
        time: '12:00 PM - 1:00 PM',
        title: 'Birds of a Feather & Lunch Break',
      },
      {
        time: '1:00 PM - 1:55 PM',
        title: 'Closing Keynote',
      },
      {
        time: '2:00 PM - 2:45 PM',
        title: 'Power Pass VIP Party',
      },
    ],
  },
]
