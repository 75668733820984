interface cookieOptionsBase {
  path?: string
  domain?: string
  expires?: Date | string
  secure?: boolean

  [propName: string]: string | Date | boolean | undefined
}

export const setCookie = (
  name: string,
  value: string,
  days = 0,
  options: cookieOptionsBase = {},
): void => {
  options = {
    path: '/',
    domain: typeof window !== 'undefined' ? location.hostname : 'igsummit.com',
    secure: true,
    ...options,
  }

  if (options.expires instanceof Date) options.expires = options.expires.toUTCString()

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    options.expires = date.toUTCString()
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`

  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`
    const optionValue = options[optionKey]
    if (optionValue !== true) updatedCookie += `=${optionValue}`
  }

  if (typeof document !== 'undefined') document.cookie = updatedCookie
}

export const getCookie = (name: string): string | undefined => {
  let matches
  if (typeof document !== 'undefined') {
    matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'),
    )
  }
  return matches ? decodeURIComponent(matches[1]) : undefined
}
