import { IQuestion } from 'components/Question'

const speakers: IQuestion[] = [
  {
    question: 'What is\u00A0the Instagram Summit powered by\u00A0ManyChat?',
    answer: (
      <>
        <p>
          Instagram Summit powered by&nbsp;ManyChat is&nbsp;the #1 virtual event for social media
          marketers, influencers, content creators, and eCommerce brands to&nbsp;learn the secrets
          to&nbsp;gain more followers, ignite engagement, and grow an&nbsp;ultra-successful brand
          on&nbsp;Instagram directly from the people who&rsquo;ve done&nbsp;it!
        </p>
        <p>
          Over two days, our handpicked all-star speakers will reveal their expert strategies
          to&nbsp;stop the scroll and convert attention into real sales.
        </p>
      </>
    ),
  },
  {
    question: 'What’s included with my ticket?',
    answer: (
      <>
        <p>A Starter Pass gives you:</p>
        <ul>
          <li>Access to keynotes and breakouts only</li>
          <li>Networking</li>
        </ul>
        <p> A Power Pass 💪 gives you:</p>
        <ul>
          <li>Access to keynotes and breakouts</li>
          <li>Access to Automation Workshops</li>
          <li>Tutorials and guides</li>
          <li>Access to networking and parties</li>
          <li>6 months access to on-demand replays</li>
          <li>Birds of a Feather interactive sessions access</li>
          <li>30 days FREE ManyChat PRO Account</li>
          <li>Digital products valued at $499</li>
        </ul>
      </>
    ),
  },
  {
    question: 'What timezone is the event?',
    answer: (
      <p>
        Instagram Summit powered by&nbsp;ManyChat is&nbsp;held in&nbsp;the US&nbsp;Pacific time zone
        (PDT).
      </p>
    ),
  },
  {
    question: 'Will I get access to replays?',
    answer: (
      <p>
        Power Pass ticket holders will receive on-demand access to&nbsp;the replays for
        6&nbsp;months.
      </p>
    ),
  },
  {
    question:
      'I’ve only purchased a\u00A0Starter Pass. Can I\u00A0upgrade to\u00A0a\u00A0Power Pass?',
    answer: (
      <p>
        We&rsquo;d love to&nbsp;be&nbsp;able to&nbsp;automatically upgrade your ticket, but
        unfortunately, we&rsquo;re not able to&nbsp;do&nbsp;that. If&nbsp;you have your heart set
        on&nbsp;a&nbsp;Power Pass, please purchase one. Email at{' '}
        <a href="mailto:events@manychat.com">events@manychat.com</a> with &laquo;Upgrade
        ticket&raquo; in&nbsp;the subject line so&nbsp;we&nbsp;can help you.
      </p>
    ),
  },
  {
    question:
      'I want to\u00A0purchase tickets for my\u00A0team so they can attend the\u00A0Summit. Do you offer group\u00A0discounts?',
    answer: (
      <p>
        Email <a href="mailto:events@manychat.com">events@manychat.com</a> and we&rsquo;ll help you
        with group ticketing options. Be&nbsp;sure to&nbsp;have all the email addresses of&nbsp;your
        team members handy!
      </p>
    ),
  },
  {
    question: 'I\u00A0want to\u00A0sponsor the Instagram Summit. Who can\u00A0I speak\u00A0to?',
    answer: (
      <p>
        We&rsquo;d love to&nbsp;hear from you! Send&nbsp;us an&nbsp;email with your company name and
        best contact number&nbsp;to <a href="mailto:events@manychat.com">events@manychat.com</a>
      </p>
    ),
  },
  {
    question:
      'I’ve never heard of\u00A0ManyChat. Can you tell me\u00A0about the company behind this event?',
    answer: (
      <>
        <p>
          ManyChat is&nbsp;the world&rsquo;s leading Chat Marketing platform helping businesses
          create meaningful conversations with their customers at&nbsp;scale.
        </p>
        <p>
          More than 1&nbsp;million businesses across 190 countries use ManyChat. Our platform powers
          over 2&nbsp;million conversations daily on&nbsp;Instagram, and we&nbsp;also facilitate 8.5
          million active conversations per day on&nbsp;Messenger.
        </p>
        <p>
          In&nbsp;fact, ManyChat is&nbsp;the only Chat Marketing platform that supports the entire
          Facebook family of&nbsp;messaging apps: Messenger, Instagram, and WhatsApp.
        </p>
      </>
    ),
  },
]

export default speakers
