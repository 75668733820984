const UkraineBanner = () => (
  <>
    <div className="text-m text-center p_a-xs">
      <svg
        viewBox="0 0 120 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ display: 'inline', height: '.7em', marginRight: '.5em' }}
      >
        <path d="M120 0H0V80H120V0Z" fill="#005BBB" />
        <path d="M120 40H0V80H120V40Z" fill="#FFD500" />
        <rect x="1" y="1" width="118" height="78" stroke="black" strokeWidth="4" />
      </svg>
      ManyChat stands with Ukraine. We are donating 100% of Instagram Summit ticket sales revenue to
      charity organizations to help relieve the humanitarian crisis in Ukraine.
    </div>
    <hr className="m_x-xs" />
  </>
)

export default UkraineBanner
