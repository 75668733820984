import styles from './TypoHero.module.css'

const TypoHero = () => (
  <section className={`grid ${styles.section}`}>
    <div className={styles.title}>
      <h1 className={`text-center`}>
        <span style={{ paddingRight: '.05em' }}>Instagram Summit</span>
      </h1>
    </div>
    <div className={`grid ${styles.grid} aic text-center`}>
      <div className="text-m-bolder">
        April 5th - 6th
        <br />
        (PDT)
      </div>
      <div className={`text-xxl ${styles.year}`}>2022</div>
      <div className="text-m-bolder">
        <div className={styles.d}>
          Powered by&nbsp;ManyChat
          <br />
          100% virtual
        </div>
        <div className={styles.m}>
          Powered
          <br />
          by&nbsp;ManyChat
        </div>
      </div>
    </div>
    <div className={`text-center m_t-s text-m-bolder ${styles.m}`}>100% virtual</div>
  </section>
)

export default TypoHero
