import videoPreview from 'public/video/video-preview.jpg'
import Feature from 'components/Feature'
import features from 'content/features'
import Tracks from 'sections/Tracks'
import tracks from 'content/tracks'
import Questions from 'sections/Questions'
import questions from 'content/questions'
import tickets from 'content/tickets'
import Ticket from 'components/Ticket'
import Promo from 'sections/Promo'
import benefits from 'content/benefits'
import Benefits from 'components/Benefits'
import React from 'react'
import { Button, SEO, WistiaVideo } from '@martech/ig-summit-ui'
import { mainPageAgenda } from 'content/agenda'
import Agenda from 'components/Agenda'
import Speakers from 'sections/Speakers'
import { mainSpeakers } from 'content/speakers'
import imagePromo from 'public/promo/image.png'
import UkraineBanner from 'components/Ukraine'

const IndexPage = () => {
  return (
    <>
      <SEO />
      <Promo image={imagePromo}>
        <p>
          Learn how to gain followers, ignite engagement, and grow an ultra-successful brand on
          Instagram directly from the people who’ve done it!
        </p>
        <Button href="https://events.hubilo.com/igsummit-2022" target="_blank">
          Go to Summit
        </Button>
      </Promo>
      <Benefits benefits={benefits} className="m_b-s" />
      <hr className="m_x-xs" />
      <div className="set-color" data-back="#6a34bf" data-front="#fff">
        <div className="relative">
          <div id="why-attend" className="anchor" />
        </div>
        <section className="m_x-xs grid gap t-grid_2 m_y-xl">
          <h2 className="text-center">Why you should attend</h2>
          <article>
            <p className="text-m">
              Instagram Summit - the brainchild of the world’s leading Chat Marketing platform,
              ManyChat - is designed to help social media marketers, influencers, content creators,
              and eCommerce brands rapidly transform their Instagram marketing strategies.
            </p>
            <p className="text-m">
              Grab a front row seat (right from your desktop!) for two days of groundbreaking
              workshops and presentations.
            </p>
          </article>
        </section>
        <section className="m_x-xs m_t-xl flex jcc">
          <WistiaVideo previewImage={videoPreview} videoId="1e9iwo0n63" />
        </section>
        <section className="m_x-xs m_b-xxl">
          <div className="w m_t-s m_b-l">
            {features.map((feature, i: number) => (
              <Feature {...feature} count={i + 1} odd={!!(i % 2)} key={i} />
            ))}
          </div>
          <div className="t-flex jcc m-m_t-xl">
            <Button href="https://events.hubilo.com/igsummit-2022" target="_blank">
              Go to Summit
            </Button>
          </div>
        </section>
      </div>
      <hr className="m_x-xs" />
      <div className="set-color" data-back="#b2287a" data-front="#fff">
        <div className="relative">
          <div id="tracks" className="anchor" />
        </div>
        <Tracks tracks={tracks} />
      </div>
      <hr className="m_x-xs" />
      <div className="set-color" data-back="#efbfd5" data-front="#020202">
        <div className="relative">
          <div id="speakers" className="anchor" />
        </div>
        <section className="m_t-xl m_b-xxl">
          <Speakers speakers={mainSpeakers} />
        </section>
      </div>
      <hr className="m_x-xs" />
      <div className="set-color" data-back="#abefa0" data-front="#020202">
        <div className="relative">
          <div id="tickets" className="anchor" />
        </div>
        <section className="m_t-xl m_b-xxl">
          <h2 className="p_x-xs m_y-xl text-center">Register today and save!</h2>
          <div className="scroll-x p-m_b-scrollbar">
            <div className="line flex shrink_0 aisth">
              {tickets.map((ticket, i) => (
                <Ticket {...ticket} key={i} />
              ))}
            </div>
          </div>
          <div className="p_x-xs">
            <article className="m_y-xl m_x-auto text-s article">
              Power Passes are non-refundable and non-transferable. Starter Passes are
              non-transferable. If you wish to purchase tickets for colleagues or staff, please
              email us: <a href="events@manychat.com">events@manychat.com</a>
            </article>
          </div>
        </section>
      </div>
      <hr className="m_x-xs" />
      <UkraineBanner />
      <div className="set-color" data-back="#D1B7DD" data-front="#020202">
        <div className="relative">
          <div id="agenda" className="anchor" />
        </div>
        <section className="m_t-xl m_b-xxl">
          <h2 className="text-center m_b-xl m_x-xs">Don’t miss the Instagram Summit</h2>
          <Agenda days={mainPageAgenda} />
          <div className="m_t-xl m_x-xs t-flex jcc">
            <Button href="/agenda">Learn more</Button>
          </div>
        </section>
      </div>
      <hr className="m_x-xs" />
      <div className="set-color" data-back="#9ADAE1" data-front="#020202">
        <div className="relative">
          <div id="faqs" className="anchor" />
        </div>
        <Questions questions={questions} />
      </div>
    </>
  )
}

export default IndexPage
