import gsap from 'gsap'
import { FC, ReactNode, useRef, useState } from 'react'
import styles from './Question.module.css'

export interface IQuestion {
  question: string
  answer: ReactNode
}

const Question: FC<IQuestion> = ({ question, answer }) => {
  const [isVisible, changeVisibility] = useState(false)
  const visibleSectionRef = useRef<HTMLDivElement>(null)
  const answerRef = useRef<HTMLParagraphElement>(null)
  const tl = gsap.timeline()

  const handleClick = () => {
    if (!isVisible) {
      tl.to(visibleSectionRef.current, { display: 'flex', duration: 0.05 }).fromTo(
        answerRef.current,
        { y: -10, opacity: 0 },
        { y: 0, opacity: 1, ease: 'power2.out', duration: 0.3 },
      )
    } else {
      tl.to(visibleSectionRef.current, { display: 'none', duration: 0.05 })
    }
    changeVisibility((prev) => !prev)
  }

  return (
    <div className={`${styles.article}`}>
      <div className={`flex aic p_y-xs  ${styles.qustionSection}`} onClick={handleClick}>
        <p className={`${styles.question} flex aic jcc text-center text-m-bolder`}>{question}</p>
        <div className={`${styles.cross} ${isVisible ? styles.close : ''}`}></div>
      </div>
      <div
        className={`${styles.visibleSection} p_b-xl p_t-xs flex aic jcc`}
        ref={visibleSectionRef}
      >
        <article className={`text-s ${styles.answer} flex fdc jcc`} ref={answerRef}>
          {answer}
        </article>
      </div>
    </div>
  )
}

export default Question
