import styles from './Agenda.module.css'

interface IAgendaItem {
  time: string
  title: string
}

export interface IAgendaDay {
  title: string
  date: string
  items: IAgendaItem[]
}

interface IAgenda {
  days: IAgendaDay[]
}

const AgendaItem: React.FC<IAgendaItem> = ({ time, title }) => (
  <div className={`grid ${styles.item} text-s`}>
    <div className="p_x-xs">{time}</div>
    <div className="p_x-xs">{title}</div>
  </div>
)

const Agenda: React.FC<IAgenda> = ({ days }) => (
  <>
    {days.map((day) => (
      <div className={`grid m_b-s ${styles.container}`} key={day.date}>
        <div className={`p_x-xs ${styles.day}`}>
          <h3>{day.title}</h3>
          <p className="text-s">{day.date}</p>
        </div>
        <div>
          {day.items.map((item) => (
            <AgendaItem {...item} key={item.time} />
          ))}
        </div>
      </div>
    ))}
  </>
)

export default Agenda
