import styles from './Button.module.css'
import React, { FC, ReactNode, useEffect, useRef } from 'react'
import gsap from 'gsap'
import Link from 'next/link'

interface IButton {
  href?: string
  size?: string
  className?: string
  target?: string
  children: ReactNode
  tag?: string
  type?: string
  disabled?: boolean
  onClick?: Function
  sign?: string
}

interface IWrapper {
  tag: string
  href: string
  children: ReactNode
}
const Wrapper: FC<IWrapper> = ({ children, tag, href }) =>
  tag === 'button' ? <>{children}</> : <Link href={href}>{children}</Link>

const Button: FC<IButton> = ({
  size = 'm',
  className = '',
  children,
  tag = 'div',
  sign = '👉',
  href = '#',
  disabled = false,
  ...rest
}) => {
  const buttonRef = useRef<HTMLElement>(null)
  const buttonAreaRef = useRef<HTMLDivElement>(null)
  const Tag: any = tag

  useEffect(() => {
    if (disabled) return
    if (buttonAreaRef.current && window.innerWidth > 1024) {
      const handleMouseMove = (e: MouseEvent) => {
        gsap.to(buttonRef.current, {
          '--x': `${e.offsetX}px`,
          '--y': `${e.offsetY}px`,
          duration: 0.5,
          ease: 'power3',
          overwrite: true,
        })
      }

      const handleMouseEnter = (e: MouseEvent) => {
        if (buttonRef.current) {
          gsap.set(buttonRef.current, {
            '--x': `${e.offsetX}px`,
            '--y': `${e.offsetY}px`,
          })
          buttonRef.current.style.setProperty('--opacity', '0.9')
          buttonRef.current.style.setProperty('--scale', '1')
        }
      }

      const handleMouseLeave = () => {
        if (buttonRef.current) {
          buttonRef.current.style.setProperty('--opacity', '0')
          buttonRef.current.style.setProperty('--scale', '0.3')
        }
      }

      buttonAreaRef.current.addEventListener('mousemove', handleMouseMove, { passive: true })
      buttonAreaRef.current.addEventListener('mouseenter', handleMouseEnter, { passive: true })
      buttonAreaRef.current.addEventListener('mouseleave', handleMouseLeave, { passive: true })
    }
  }, [])

  return (
    <div className={styles['button-area']} ref={buttonAreaRef}>
      <Wrapper tag={tag} href={href}>
        <Tag
          className={`${size === 'm' ? 'text-m-bolder' : 'text-action'} ${styles.button} ${
            styles[`button_${size}`]
          } ${disabled ? styles.disabled : ''} bg-black text-white ${className}`}
          ref={buttonRef}
          data-sign={sign}
          {...rest}
        >
          <div className={styles.dot} />
          <span>{children}</span>
        </Tag>
      </Wrapper>
    </div>
  )
}

export default Button
