import { StaticImageData } from 'next/image'
import React, { useState } from 'react'
import styles from '../WistiaVideo/WistiaVideo.module.css'

export interface IYoutubeVideoProps {
  previewImage: StaticImageData
  videoId: string
  className?: string
}

const YoutubeVideo: React.FC<IYoutubeVideoProps> = ({ previewImage, videoId, className }) => {
  const [isPlay, setIsPlay] = useState(false)
  const handlePlay = () => setIsPlay(true)

  return (
    <div className={styles.wrapper}>
      <div className={styles['video-background']} />
      <div className={`${styles['video-container']} ${className} b_rd-m`}>
        {isPlay ? (
          <iframe
            className={styles.iframe}
            src={
              'https://www.youtube.com/embed/' + videoId + '?title=0&byline=0&portrait=0&autoplay=1'
            }
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen={true}
          />
        ) : (
          <div
            className={`play ${styles.thumbnail}`}
            style={{ backgroundImage: `url(${previewImage.src})` }}
            onClick={handlePlay}
          />
        )}
      </div>
    </div>
  )
}

export default YoutubeVideo
