import React, { FC, useEffect, useRef } from 'react'
import { Button } from '@martech/ig-summit-ui'
import styles from './Navigation.module.css'
import Link from 'next/link'
import { useRouter } from 'next/router'

interface INavigation {
  onClick: (name: string, e: React.MouseEvent<HTMLAnchorElement>) => void
  isOpen: boolean
  toggleOpen: () => void
  close: () => void
}

const Navigation: FC<INavigation> = ({ onClick, isOpen, toggleOpen, close }) => {
  const navigationRef = useRef<HTMLElement>(null)
  const router = useRouter()

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        isOpen &&
        navigationRef.current &&
        !navigationRef.current.contains(e.target as HTMLElement)
      )
        toggleOpen()
    }
    window.addEventListener('click', handleClick, { passive: true })

    return () => window.removeEventListener('click', handleClick)
  }, [isOpen])

  useEffect(() => {
    const handleRouteChange = () => close()

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return (
    <nav
      className={`${styles.container} ${isOpen ? styles.open : ''} color-transition`}
      ref={navigationRef}
    >
      <Link href="/" className={styles.logo} onClick={onClick.bind(null, '_')}>
        <svg viewBox="0 0 505 137" className={styles.fill}>
          <path d="M106.015 9.0237C107.597 10.068 109.273 11.2767 110.591 12.321H43.9433C62.7114 0.612133 82.0303 -6.62986 106.015 9.0237ZM137.251 56.3055C134.901 53.9768 131.975 51.3714 128.336 49.5439C133.857 43.3566 152.484 18.4169 154.508 15.4225C147.287 16.2709 135.45 19.6961 128.296 21.3121V102.433C137.739 95.3298 148.735 86.5083 147.143 73.7787C146.287 66.9283 142.008 61.0439 137.251 56.3055ZM17.8078 104.903V33.2403C9.29445 42.8319 1.24322 55.4857 0.911665 68.5678C0.548782 82.4017 9.00466 94.2464 17.8078 104.903ZM50.1801 132.798C58.811 137.565 68.0319 137.458 76.1667 134.453C84.4164 131.407 92.225 127.381 99.7489 122.823H36.3097C40.6799 126.532 45.2616 130.088 50.1801 132.798Z" />
          <path d="M25.1185 25.9695C30.9281 20.814 37.232 16.2441 43.9387 12.3261H17.8086V33.2454C20.0928 30.6716 22.5341 28.2417 25.1185 25.9695Z" />
          <path d="M110.592 12.3211C113.954 14.6864 115.97 18.2212 118.277 22.3774C119.27 24.1579 120.277 23.8995 122.499 23.205C122.992 23.0484 127.72 21.4428 128.297 21.3123V12.3211H110.592Z" />
          <path d="M99.734 122.826H36.2948C33.9791 120.863 31.7183 118.858 29.5071 116.913C25.288 113.229 21.3707 109.213 17.793 104.904V122.826H128.295V102.434L125.423 104.598C117.181 110.817 108.738 117.372 99.734 122.826Z" />
          <path d="M195.18 16.6796H183.32V61.8232H195.18V16.6796Z" />
          <path d="M212.992 42.5137C213.016 38.6563 215.46 37.5881 219.174 37.5881C222.887 37.5881 225.092 38.6122 225.068 42.3594V61.8232H236.784V40.2433C236.808 32.6165 231.752 27.5247 224.013 27.5247C218.642 27.5247 214.556 29.9969 212.886 34.0284C212.848 34.1203 212.759 34.1816 212.659 34.1816C212.526 34.1816 212.417 34.073 212.417 33.9391V27.9655H201.276V61.8232H212.992V42.5137Z" />
          <path d="M274.41 38.3036C273.787 31.6026 267.821 27.5247 257.854 27.5247C247.887 27.5247 241.537 31.5144 241.585 38.3036C241.537 43.5057 245.155 46.8562 252.511 48.1126L258.932 49.2368C261.975 49.7878 263.364 50.6696 263.412 52.1685C263.364 53.8658 261.304 54.9899 258.309 54.9899C254.979 54.9899 252.726 53.6674 252.223 51.1986L240.651 51.4631C241.489 58.1641 247.743 62.4624 258.261 62.4624C268.108 62.4624 275.272 57.9437 275.32 50.9782C275.272 46.0406 271.726 43.1089 264.395 41.8084L257.303 40.574C254.188 40.0009 253.182 38.9869 253.206 37.6864C253.182 35.967 255.362 34.909 258.069 34.909C261.16 34.909 263.317 36.4299 263.652 38.5681L274.41 38.3036Z" />
          <path d="M300.512 27.9655H293.875V19.8538H282.159V23.1017C282.159 25.7879 279.981 27.9655 277.295 27.9655V35.9009H282.159V52.3668C282.087 59.2221 286.927 62.6608 295.193 62.3081C298.02 62.1759 300.057 61.6468 301.183 61.3382L299.41 53.5572C298.907 53.6674 297.733 53.8878 296.846 53.8878C294.953 53.8878 293.875 53.1824 293.875 51.1765V35.9009H300.512V27.9655Z" />
          <path d="M315.515 62.3963C320.642 62.3963 324.188 60.5667 326.201 57.106H326.488V61.8232H337.51V38.8106C337.51 31.6467 330.586 27.5247 321.217 27.5247C311.322 27.5247 305.716 32.1096 304.781 38.2815L315.587 38.6342C316.09 36.474 318.031 35.1515 321.122 35.1515C323.997 35.1515 325.818 36.4299 325.818 38.7003V38.8106C325.818 40.8826 323.374 41.3234 317.096 41.8304C309.645 42.4035 303.415 44.9384 303.415 52.433C303.415 59.134 308.471 62.3963 315.515 62.3963ZM319.133 55.3426C316.425 55.3426 314.509 54.1523 314.509 51.9039C314.509 49.7217 316.378 48.3991 319.708 47.9363C321.888 47.6497 324.572 47.2088 325.889 46.5696V49.7878C325.889 53.0943 322.871 55.3426 319.133 55.3426Z" />
          <path d="M360.479 75.2252C371.573 75.2252 379.144 70.5742 379.144 61.9554V27.9655H367.476V33.7628H367.14C365.726 30.6547 362.516 27.5247 356.718 27.5247C349.027 27.5247 342.054 32.9692 342.054 44.8503C342.054 56.3566 348.691 61.3603 356.766 61.3603C362.18 61.3603 365.798 59.0458 367.188 55.9157H367.595V61.8673C367.595 66.1877 364.72 67.7527 360.791 67.7527C357.029 67.7527 354.777 66.4301 354.154 64.3361L343.061 64.9092C343.923 70.8387 349.985 75.2252 360.479 75.2252ZM360.839 53.5131C356.502 53.5131 354.082 50.2728 354.082 44.8503C354.082 39.4498 356.478 35.945 360.839 35.945C365.127 35.945 367.619 39.3616 367.619 44.8503C367.619 50.2948 365.104 53.5131 360.839 53.5131Z" />
          <path d="M385.264 61.8232H396.98V43.4395C396.98 39.4057 400.095 36.6944 404.288 36.6944C405.677 36.6944 407.762 36.9149 408.888 37.2676V27.8994C407.906 27.6569 406.684 27.5026 405.605 27.5026C401.58 27.5026 398.37 29.6628 397.028 34.1375H396.645V27.9655H385.264V61.8232Z" />
          <path d="M422.527 62.3963C427.655 62.3963 431.201 60.5667 433.213 57.106H433.501V61.8232H444.522V38.8106C444.522 31.6467 437.598 27.5247 428.23 27.5247C418.335 27.5247 412.728 32.1096 411.794 38.2815L422.599 38.6342C423.103 36.474 425.043 35.1515 428.134 35.1515C431.009 35.1515 432.83 36.4299 432.83 38.7003V38.8106C432.83 40.8826 430.386 41.3234 424.109 41.8304C416.657 42.4035 410.428 44.9384 410.428 52.433C410.428 59.134 415.483 62.3963 422.527 62.3963ZM426.145 55.3426C423.438 55.3426 421.521 54.1523 421.521 51.9039C421.521 49.7217 423.39 48.3991 426.72 47.9363C428.901 47.6497 431.584 47.2088 432.902 46.5696V49.7878C432.902 53.0943 429.883 55.3426 426.145 55.3426Z" />
          <path d="M449.113 61.8232H460.829V41.7863C460.829 38.4358 462.961 37.522 466.028 37.522C469.071 37.522 471.083 38.2595 471.083 41.3896V61.8232H482.344V41.61C482.344 38.3697 484.285 37.522 487.52 37.522C490.491 37.522 492.599 38.1272 492.599 41.4998V61.8232H504.315V39.031C504.315 31.8671 499.523 27.5247 492.575 27.5247C487.136 27.5247 482.848 30.1477 481.482 34.1816H481.098C480.116 30.1257 476.163 27.5247 471.059 27.5247C466.028 27.5247 462.194 30.0596 460.661 34.1816H460.254V27.9655H449.113V61.8232Z" />
          <path d="M209.843 86.1551H221.2C221.104 77.7127 213.796 72.0036 202.392 72.0036C191.179 72.0036 183.104 77.6245 183.176 86.0228C183.152 92.8781 188.375 96.7356 196.857 98.5211L201.984 99.6232C207.375 100.769 209.795 102.114 209.843 104.649C209.795 107.404 206.992 109.322 202.224 109.322C196.977 109.322 193.527 107.074 193.263 102.731H181.906C182.05 113.29 190.029 118.338 202.368 118.338C214.587 118.338 221.823 113.246 221.871 104.671C221.823 97.463 216.528 93.0545 206.848 91.0927L202.631 90.211C198.175 89.3293 195.348 87.9626 195.443 85.2734C195.467 82.8046 197.767 81.0191 202.368 81.0191C206.992 81.0191 209.532 82.9369 209.843 86.1551Z" />
          <path d="M250.255 100.702C250.255 104.647 247.524 108.076 244.05 108.076C240.456 108.076 238.251 107.052 238.227 103.37V83.9067H226.511V105.487C226.535 113.135 231.591 118.205 239.162 118.205C244.673 118.205 248.77 115.604 250.399 111.46H250.782V117.764H261.947V83.9067H250.255V100.702Z" />
          <path d="M268.055 117.764H279.772V97.7275C279.772 94.377 281.904 93.4632 284.971 93.4632C288.014 93.4632 290.026 94.2007 290.026 97.3308V117.764H301.287V97.5512C301.287 94.3109 303.228 93.4632 306.462 93.4632C309.433 93.4632 311.542 94.0684 311.542 97.441V117.764H323.258V94.9722C323.258 87.8083 318.466 83.4659 311.518 83.4659C306.079 83.4659 301.79 86.089 300.425 90.1228H300.041C299.059 86.0669 295.106 83.4659 290.002 83.4659C284.971 83.4659 281.137 86.0008 279.604 90.1228H279.197V83.9067H268.055V117.764Z" />
          <path d="M329.168 117.764H340.885V97.7275C340.885 94.377 343.017 93.4632 346.084 93.4632C349.127 93.4632 351.139 94.2007 351.139 97.3308V117.764H362.4V97.5512C362.4 94.3109 364.341 93.4632 367.576 93.4632C370.546 93.4632 372.655 94.0684 372.655 97.441V117.764H384.371V94.9722C384.371 87.8083 379.579 83.4659 372.631 83.4659C367.192 83.4659 362.903 86.089 361.538 90.1228H361.154C360.172 86.0669 356.219 83.4659 351.115 83.4659C346.084 83.4659 342.25 86.0008 340.717 90.1228H340.31V83.9067H329.168V117.764Z" />
          <path d="M390.281 117.764H401.998V83.9067H390.281V117.764ZM396.152 79.9611C399.482 79.9611 402.165 77.6466 402.165 74.8031C402.165 71.9596 399.482 69.6671 396.152 69.6671C392.845 69.6671 390.138 71.9596 390.138 74.8031C390.138 77.6466 392.845 79.9611 396.152 79.9611Z" />
          <path d="M428.879 83.9067H422.242V75.795H410.526V79.0429C410.526 81.7291 408.348 83.9067 405.662 83.9067V91.8421H410.526V108.308C410.454 115.163 415.294 118.602 423.56 118.249C426.387 118.117 428.423 117.588 429.549 117.279L427.776 109.498C427.273 109.609 426.099 109.829 425.213 109.829C423.32 109.829 422.242 109.124 422.242 107.118V91.8421H428.879V83.9067Z" />
        </svg>
      </Link>
      <div className={`text-m-bolder t-text-action ${styles.links}`}>
        <Link
          href="/#why-attend"
          className="p_x-xxs p_y-xs"
          onClick={onClick.bind(null, 'why-attend')}
        >
          Why Attend
        </Link>
        <Link href="/#tracks" className="p_x-xxs p_y-xs" onClick={onClick.bind(null, 'tracks')}>
          Tracks
        </Link>
        <Link href="/speakers" className="p_x-xxs p_y-xs">
          Speakers
        </Link>
        <Link href="/#tickets" className="p_x-xxs p_y-xs" onClick={onClick.bind(null, 'tickets')}>
          Tickets
        </Link>
        <Link href="/agenda" className="p_x-xxs p_y-xs">
          Agenda
        </Link>
        <Link href="/#faqs" className="p_x-xxs p_y-xs" onClick={onClick.bind(null, 'faqs')}>
          FAQs
        </Link>
      </div>
      <Button
        href="https://events.hubilo.com/igsummit-2022"
        target="_blank"
        className={styles.button}
        size="s"
      >
        Go to Summit
      </Button>
      <div className={`text-s ${styles.burger}`} role="button" onClick={toggleOpen}>
        Menu
      </div>
    </nav>
  )
}

export default Navigation
