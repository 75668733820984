import { Button } from '@martech/ig-summit-ui'
import Question, { IQuestion } from 'components/Question'

export interface IQuestions {
  questions: IQuestion[]
}

const Questions: React.FC<IQuestions> = ({ questions }) => (
  <section className="m_x-xs m_t-xl m_b-xxl">
    <h2 className="text-center m_y-xl">FAQs</h2>
    <div>
      {questions.map((question) => (
        <Question {...question} key={question.question} />
      ))}
    </div>

    <div className="m_t-xxl t-flex jcc">
      <Button href="https://events.hubilo.com/igsummit-2022" target="_blank">
        Go to Summit
      </Button>
    </div>
  </section>
)

export default Questions
